import React, { useState, useEffect } from 'react';
import { useLocation } from '@gatsbyjs/reach-router'
import styled from 'styled-components';
import {useNavigationTree} from "../../hooks/use-navigation";
import Title from "../Sidebar/Title";
import {Book} from "react-feather";
//import manualNavigationData from "../../navigation/contentcreator.json"


const Nav = styled.nav`
  margin-top: 20px;

  ul {
    list-style-type: none;
  }

  .nav-item {
    display: flex;
    align-items: center;
    color: #636363;
  }

  .nav-item button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    color: #636363;
  }

  .nav-item a {
    text-decoration: none;
    color: #636363;
  }

  .nav-item a:hover {
    text-decoration: underline;
  }

  .nav-item.selected a{
    font-weight: bold;
  }
`;

const Style = styled.div`
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 48px 15px 48px 15px;
  position: fixed;
  max-height: calc(100vh - 52px - 70px);
  max-width: 22rem;
  top: 122px;
  color: #636363;


  &.fullscreen {
    position: revert;
    max-height: revert;
    max-width: revert;
    top: revert;
  }

  > div + div {
    margin-top: 10px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,255,0,0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,255,0,0.5);
  }

  a, a:hover, a:visited, a:active {
    text-decoration: none;
  }
`;

const Simpletoc = ({ rootName }) => {
  const [openNodes, setOpenNodes] = useState({});
  const [navigationTree, setNavigationTree] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const location = useLocation();
  const navigation = useNavigationTree();
  const rootNameWithoutDash = rootName.replace(/-/g, ' ');
  //console.log("I'm in SimpleToc and the navigation is:" , JSON.stringify(navigation, null, 2));

  useEffect(() => {
    if (Object.keys(navigation).length > 0 && !navigationTree) {
      setNavigationTree(navigation);
    }
  }, [navigation, navigationTree]);

  useEffect(() => {
    if (navigationTree) {
      const slug = location.pathname.replace(/^\//, '');
      console.log("I'm in useEffect and the slug is: ", slug);
      setSelectedItem(slug); // Ensure selectedItem is set correctly
      const pathSegments = slug.split('/');
      const newOpenNodes = {};
      let currentPath = '';
      pathSegments.forEach(segment => {
        currentPath = currentPath ? `${currentPath}/${segment}` : segment;
        newOpenNodes[currentPath] = true;
      });
      setOpenNodes(newOpenNodes);
    }
  }, [location.pathname, navigationTree]);

  const toggleNode = (link) => {
    setOpenNodes((prevOpenNodes) => ({
      ...prevOpenNodes,
      [link]: !prevOpenNodes[link],
    }));
  };

  const handleItemClick = (slug) => {
    setSelectedItem(slug); // Ensure selectedItem is set correctly
    setOpenNodes((prevOpenNodes) => {
      const newOpenNodes = { [slug]: true };
      const pathSegments = slug.split('/');
      let currentPath = '';
      pathSegments.forEach(segment => {
        currentPath = currentPath ? `${currentPath}/${segment}` : segment;
        newOpenNodes[currentPath] = true;
      });
      //console.log("The currently selected item is:" + slug + "and the openNodes are: ", JSON.stringify(newOpenNodes, null, 2));
      return newOpenNodes;
    });
  };

  const renderItems = (items) => {
    return (
      <ul>
        {items.map(item => (
          <li key={item.slug}>
            <div className={`nav-item ${selectedItem === item.slug + "/" ? 'selected' : ''}`}> {/* Use selectedItem to apply selected class */}
              {item.hasChildren && (
                <button onClick={() => toggleNode(item.slug)}>
                  {openNodes[item.slug] ? '-' : '+'}
                </button>
              )}
              <a href={item.slug.startsWith("http") ? item.slug : "/" + item.slug} onClick={() => handleItemClick(item.slug)}>
                {item.label}
              </a>
            </div>
            {item.hasChildren && openNodes[item.slug] && renderItems(item.children)}
          </li>
        ))}
      </ul>
    );
  };

  if (!navigationTree) {
    return <div>Loading...</div>;
  }

  let navigationData = [];
  if (rootName && navigation[rootName]) {
    navigationData = navigation[rootName].children;
  }

  return (
    <Style>
      <Title icon={<Book />}>{rootNameWithoutDash}</Title>
      <Nav>
        {renderItems(navigationData)}
      </Nav>
    </Style>
  );
};

export default Simpletoc;
